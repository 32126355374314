import { CustomRoutes, Admin, Resource } from 'react-admin';
import { Route } from 'react-router';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { APIProvider } from '@vis.gl/react-google-maps';

// import providers
import authProvider from './providers/AuthProvider';
import dataProvider from './providers/data-provider';

// import layout
import { Login, Layout } from './layout';
import { darkTheme } from './layout/themes';
import { Analytics } from './pages/analytics';

// import resources
import refueling from './pages/refueling';
import expenses from './pages/expenses';
import services from './pages/services';
import incoming from './pages/incoming';
import reminders from './pages/reminders';
import drivers from './pages/drivers';
import routes from './pages/routes';
import vehicles from './pages/vehicles';
import vehicleGroup from './pages/vehicle-group';
import tasks from './pages/tasks';
import vendors from './pages/vendors';
import Help from './pages/help/Help';
import Feedback from './pages/feedback/Feedback';
import expensesType from './pages/expenses-type';
import fuel from './pages/fuel';
import fuelTypes from './pages/fuel-types';
import serviceType from './pages/service-type';
import incomingsType from './pages/incomings-type';
import makes from './pages/makes';
import makeModel from './pages/make-model';
import plans from './pages/plans';
import addons from './pages/addons';
import subscriptions from './pages/subscriptions';

// import pages
import Settings from './pages/settings/Settings';
import Reports from './pages/reports/Reports';
import GpsTrack from './pages/gps-track/GpsTrack';
import TaxiDrivers from './pages/taxi-dispatch/drivers/TaxiDrivers';
import CreateDriver from './pages/taxi-dispatch/drivers/Create';
import DashboardDispatch from './pages/taxi-dispatch/dashboard/Dashboard';
import NewOrders from './pages/taxi-dispatch/orders/NewOrders';
import InProccessOrders from './pages/taxi-dispatch/orders/InProccessOrders';
import RejectedOrders from './pages/taxi-dispatch/orders/RejectedOrders';
import CancelledOrders from './pages/taxi-dispatch/orders/CancelledOrder';
import AssignedOrders from './pages/taxi-dispatch/orders/AssignedOrders';
import AcceptedOrders from './pages/taxi-dispatch/orders/AcceptedOrders';
import CompletedOrders from './pages/taxi-dispatch/orders/CompletedOrders';
import EditOrder from './pages/taxi-dispatch/orders/EditOrder';

const App = () => {

	return (
		<APIProvider apiKey={'AIzaSyDyN9j33W0zuEbdoySoRkq1YqIsSuiEkjw'} onLoad={() => console.log('Maps API has loaded.')}>
			<Admin
				title="Auttom.io - Welcome to auttom"
				dataProvider={dataProvider}
				authProvider={authProvider}
				dashboard={DashboardDispatch}
				loginPage={Login}
				layout={Layout}
				disableTelemetry
				theme={darkTheme}
			>
				<CustomRoutes>
					<Route path="/taxi-orders/new-orders" element={<NewOrders />} />
					<Route path="/taxi-orders/inproccess-orders" element={<InProccessOrders />} />
					<Route path="/taxi-orders/assigned-orders" element={<AssignedOrders />} />
					<Route path="/taxi-orders/accepted-orders" element={<AcceptedOrders />} />
					<Route path="/taxi-orders/rejected-orders" element={<RejectedOrders />} />
					<Route path="/taxi-orders/cancelled-orders" element={<CancelledOrders />} />
					<Route path="/taxi-orders/completed-orders" element={<CompletedOrders />} />
					<Route path="/taxi-orders/edit/:id" element={<EditOrder />} />
					<Route path="/taxi-drivers" element={<TaxiDrivers />} />
					<Route path="/create-driver" element={<CreateDriver />} />
				</CustomRoutes>
				<Resource name="refueling" {...refueling} />
				<Resource name="expenses" {...expenses} />
				<Resource name="service" {...services} />
				<Resource name="incomings" {...incoming} />
				<Resource name="reminders" {...reminders} />
				<Resource name="drivers" {...drivers} />
				<Resource name="route" {...routes} />
				<Resource name="vehicles" {...vehicles} />
				<Resource name="vehicle-groups" {...vehicleGroup} />
				<Resource name="tasks" {...tasks} />
				<Resource name="vendors" {...vendors} />
				<Resource name="expenses-type" {...expensesType} />
				<Resource name="fuel" {...fuel} />
				<Resource name="fuel-types" {...fuelTypes} />
				<Resource name="service-type" {...serviceType} />
				<Resource name="incomings-type" {...incomingsType} />
				<Resource name="makes" {...makes} />
				<Resource name="make-model" {...makeModel} />
				<Resource name="plans" {...plans} />
				<Resource name="addons" {...addons} />
				<Resource name="subscriptions" {...subscriptions} />
			</Admin>
		</APIProvider>
	)

};

export default App;

{/* <CustomRoutes>

<Route path="/taxi-orders" element={<TaxiOrders />} />
<Route path="/create-orders" element={<CreateOrder />} />
<Route path="/taxi-drivers" element={<TaxiDrivers />} />
<Route path="/create-driver" element={<CreateDriver />} />

<Route path="/reports" element={<Reports />} />
<Route path="/settings" element={<Settings />} />
<Route path="/help" element={<Help />} />
<Route path="/feedback" element={<Feedback />} />
</CustomRoutes>
<Resource name="refueling" {...refueling} />
<Resource name="expenses" {...expenses} />
<Resource name="service" {...services} />
<Resource name="incomings" {...incoming} />
<Resource name="reminders" {...reminders} />
<Resource name="drivers" {...drivers} />
<Resource name="route" {...routes} />
<Resource name="vehicles" {...vehicles} />
<Resource name="vehicle-groups" {...vehicleGroup} />
<Resource name="tasks" {...tasks} />
<Resource name="vendors" {...vendors} />
<Resource name="expenses-type" {...expensesType} />
<Resource name="fuel" {...fuel} />
<Resource name="fuel-types" {...fuelTypes} />
<Resource name="service-type" {...serviceType} />
<Resource name="incomings-type" {...incomingsType} />
<Resource name="makes" {...makes} />
<Resource name="make-model" {...makeModel} />
<Resource name="plans" {...plans} />
<Resource name="addons" {...addons} />
<Resource name="subscriptions" {...subscriptions} /> */}
import * as React from 'react';
import Box from '@mui/material/Box';
import { Button, Typography } from '@mui/material';
import { Check, Sync, Block, Cancel, Assignment, CheckCircle } from '@mui/icons-material';
import { useGetOne } from 'react-admin';

const OrderStatus = () => {

    const [currentHash, setCurrentHash] = React.useState(window.location.hash);
    const [statusMenu, setStatusMenu] = React.useState([]);

    const { data, isLoading, error, refetch } = useGetOne(
        'taxi-orders/total-by-status',
        { id: "" }
    );

    React.useEffect(() => {

        const handleHashChange = () => {
            setCurrentHash(window.location.hash);
        };

        window.addEventListener("hashchange", handleHashChange);

        return () => {
            window.removeEventListener("hashchange", handleHashChange);
        };

    }, []);

    const menuItems = [
        { label: 'New Orders', status: 'NEW_ORDER', href: '/taxi-orders/new-orders', icon: <Check /> },
        { label: 'In Process', status: 'IN_PROGRESS', href: '/taxi-orders/inproccess-orders', icon: <Sync /> },
        { label: 'Assigned', status: 'ASSIGNED', href: '/taxi-orders/assigned-orders', icon: <Assignment /> },
        { label: 'Accepted', status: 'ACCEPTED', href: '/taxi-orders/accepted-orders', icon: <CheckCircle /> },
        { label: 'Rejected', status: 'REJECTED', href: '/taxi-orders/rejected-orders', icon: <Block /> },
        { label: 'Cancelled', status: 'CANCELLED', href: '/taxi-orders/cancelled-orders', icon: <Cancel /> },
        { label: 'Completed', status: 'COMPLETED', href: '/taxi-orders/completed-orders', icon: <Check /> },
    ];

    React.useEffect(() => {

        const intervalId = setInterval(() => {
            refetch();
        }, 10000);

        return () => {
            clearInterval(intervalId);
        };

    }, [refetch])

    React.useEffect(() => {

        if (data) {

            let statusMenuTemp: any = [];

            for (let index = 0; index < menuItems.length; index++) {

                const element: any = { ...menuItems[index] };
                let found = false;

                Object.keys(data).forEach(key => {
                    if (element.status === data[key].status) {
                        element.total = data[key].total;
                        found = true;
                    }
                });

                if (!found) {
                    element.total = 0;
                }

                statusMenuTemp.push(element);
            }

            setStatusMenu(statusMenuTemp);

        }

    }, [data]);

    return (
        <Box>
            <Box sx={{ display: "flex" }}>
                {statusMenu.map((item: any, index) => (
                    <Box sx={{ mt: 2, ml: index === 0 ? 0 : 2 }} key={item.label}>
                        <Button
                            href={`/#${item.href}`}
                            variant="contained"
                            size="large"
                            startIcon={item.icon}
                            sx={{
                                background: currentHash.startsWith(`#${item.href}`) ? "linear-gradient(145deg, #ef4e55, #ff9194)" : "linear-gradient(145deg, #222, #333)",
                                pl: 3,
                                pr: 3,
                                borderRadius: 3,
                                '&:hover': {
                                    backgroundColor: "#222",
                                    color: "#ddd"
                                }
                            }}
                        >
                            {item.label}
                            <Typography
                                component="span"
                                sx={{
                                    color: '#ffcc00',
                                    fontWeight: 'bold',
                                    ml: 1
                                }}
                            >
                                ({item.total})
                            </Typography>
                        </Button>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default OrderStatus;

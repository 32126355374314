import { Box } from '@mui/material';

// import images
import LogoDefault from '../assets/images/login_logo.png';

const Logo = () => {

    return (
        <Box>
            <img src={LogoDefault} width={120} />
        </Box>
    );

};

export default Logo;
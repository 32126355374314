import * as React from 'react';
import Box from '@mui/material/Box';
import { useMediaQuery, Theme, Typography, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, List, ListItem, ListItemText, ListItemButton, TextField } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useGetList, useUpdate, useNotify, useRefresh, Pagination, ListBase, Loading, useListContext } from 'react-admin';
import OrderStatus from './OrderStatus';
import { format } from 'date-fns';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import EditIcon from '@mui/icons-material/Edit';

const NewOrders = () => {
    const [dateValue, setDateValue] = React.useState(new Date());

    return (
        <ListBase
            resource="taxi-orders"
            perPage={10}
            sort={{ field: 'date', order: 'DESC' }}
            filter={{ status: "NEW_ORDER", date: format(new Date(dateValue), "yyyy-MM-dd") }}
        >
            <OrderTable dateValue={dateValue} setDateValue={setDateValue} />
        </ListBase>
    );
};

const OrderTable = ({ dateValue, setDateValue }: any) => {

    const notify = useNotify();
    const refresh = useRefresh();

    const [openDialog, setOpenDialog] = React.useState(false);
    const [selectedOrder, setSelectedOrder] = React.useState<string | null>(null);

    const { data: drivers, total: totalDrivers, isLoading: isLoadingDrivers, error: errorDrivers } = useGetList(
        'drivers',
        {
            pagination: { page: 1, perPage: 23 },
            sort: { field: 'date', order: 'DESC' },
            filter: {}
        }
    )

    const [update, { isLoading, error, data }] = useUpdate();

    const handleDialogOpen = (orderId: string) => {
        setSelectedOrder(orderId);
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        setSelectedOrder(null);
    };

    const { data: orders, total, isLoading: isLoadingOders } = useListContext();

    if (isLoadingOders) return <Loading />;

    return (
        <Box sx={{ mt: 6, p: 4, background: "linear-gradient(145deg, #222, #333)", borderRadius: 5, ml: 4, mr: 4 }}>
            <Typography fontSize={22} ml={1} color="#ddd" fontWeight="bold">New Orders</Typography>
            <Box sx={{ pt: 2, width: 300 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="Orders Date"
                        value={dateValue}
                        onChange={(newValue) => setDateValue(newValue)}
                        renderInput={(params) => <TextField fullWidth {...params} />}
                    />
                </LocalizationProvider>
            </Box>
            <Box>
                <OrderStatus />
            </Box>
            <Box sx={{ mt: 2 }}>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Client</TableCell>
                                <TableCell>Phone</TableCell>
                                <TableCell>Destination(s)</TableCell>
                                <TableCell>Assign to driver</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        {orders?.length > 0 ? (
                            <TableBody>
                                {orders?.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell>{row.client_name ? row.client_name : "No Name"}</TableCell>
                                        <TableCell>{row.client_phone ? row.client_phone : "No Number"}</TableCell>
                                        <TableCell>
                                            {row.pickup.length > 50
                                                ? row.pickup.substring(0, 50) + "..."
                                                : row.pickup}
                                            {" - "}
                                            {row.dropoff.length > 50
                                                ? row.dropoff.substring(0, 50) + "..."
                                                : row.dropoff}
                                        </TableCell>
                                        <TableCell>
                                            {row.driver_id ? (
                                                row.driver.name
                                            ) : (
                                                <Button
                                                    variant="contained"
                                                    size="small"
                                                    onClick={() => handleDialogOpen(row.id)}
                                                    sx={{
                                                        backgroundColor: "#fff",
                                                        color: "#222",
                                                        pl: 3,
                                                        pr: 3,
                                                        borderRadius: 3,
                                                        '&:hover': {
                                                            backgroundColor: "#fff",
                                                            color: "#222"
                                                        }
                                                    }}
                                                >
                                                    Assign Driver
                                                </Button>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                startIcon={<EditIcon />}
                                                href={`/#/taxi-orders/edit/${row.id}`}
                                            >
                                                Edit
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        ) : (
                            <Box p={2}>
                                <Typography color="gray">No data available</Typography>
                            </Box>
                        )}
                    </Table>
                </TableContainer>
                {/* Pagination Control */}
                <Pagination />
            </Box>
            <Dialog
                open={openDialog}
                onClose={handleDialogClose}
                maxWidth="sm"
                fullWidth
            >
                <DialogContent sx={{ backgroundColor: "#222", color: "#fff", p: 1 }}>
                    <Typography sx={{ pl: 2.7, pt: 1, color: "#ddd" }}>Assign Driver</Typography>
                    {isLoadingDrivers ? (
                        <CircularProgress />
                    ) : (
                        <List>
                            {drivers?.map((driver: any) => (
                                <ListItem key={driver.id} onClick={() => {
                                    update('taxi-orders/assign-driver', {
                                        data: {
                                            driverId: driver.drivers[0].id
                                        }, id: `${selectedOrder}`
                                    }).then(() => {
                                        notify("Driver assigned successfully", { type: "success" });
                                        handleDialogClose();
                                        refresh();
                                    }).catch(() => {
                                        notify("Driver was not assigned successfully", { type: "error" });
                                        handleDialogClose();
                                    })
                                }}>
                                    <ListItemButton sx={{ backgroundColor: "#333", borderRadius: 5 }}>
                                        <ListItemText primary={driver.drivers[0].name} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    )}
                </DialogContent>
            </Dialog>

        </Box>
    );
};

export default NewOrders;
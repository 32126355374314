import * as React from 'react';
import { Box, Typography, Button, Chip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField } from '@mui/material';
import { useListContext, ListBase, Pagination, Loading, Error } from 'react-admin';
import { format } from 'date-fns';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import EditIcon from '@mui/icons-material/Edit';
import OrderStatus from './OrderStatus';

const AcceptedOrders = () => {
    const [dateValue, setDateValue] = React.useState(new Date());

    return (
        <ListBase
            resource="taxi-orders"
            perPage={10}
            sort={{ field: 'date', order: 'DESC' }}
            filter={{ status: "ACCEPTED", date: format(new Date(dateValue), "yyyy-MM-dd") }}
        >
            <OrderTable dateValue={dateValue} setDateValue={setDateValue} />
        </ListBase>
    );
};

const OrderTable = ({ dateValue, setDateValue }: any) => {

    const { data: orders, total, isLoading, error } = useListContext();

    if (isLoading) return <Loading />;

    return (
        <Box sx={{ mt: 6, p: 4, background: "linear-gradient(145deg, #222, #333)", borderRadius: 5, ml: 4, mr: 4 }}>
            <Typography fontSize={22} ml={1} color="#ddd" fontWeight="bold">Accepted Orders</Typography>
            <Box sx={{ pt: 2, width: 300 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="Orders Date"
                        value={dateValue}
                        onChange={(newValue) => {
                            setDateValue(newValue);
                        }}
                        renderInput={(params) => <TextField fullWidth {...params} />}
                    />
                </LocalizationProvider>
            </Box>
            <Box>
                <OrderStatus />
            </Box>
            <Box sx={{ mt: 2 }}>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>Client</TableCell>
                                <TableCell>Phone</TableCell>
                                <TableCell>Destination(s)</TableCell>
                                <TableCell>Assigned to</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        {orders?.length > 0 ? (
                            <TableBody>
                                {orders.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell>{format(new Date(row.date), "yyyy-MM-dd")}</TableCell>
                                        <TableCell>{row.client_name || "No Name"}</TableCell>
                                        <TableCell>{row.client_phone || "No Number"}</TableCell>
                                        <TableCell>
                                            {row.pickup.length > 50
                                                ? row.pickup.substring(0, 50) + "..."
                                                : row.pickup}
                                            {" - "}
                                            {row.dropoff.length > 50
                                                ? row.dropoff.substring(0, 50) + "..."
                                                : row.dropoff}
                                        </TableCell>
                                        <TableCell>
                                            <Chip label={row.driver?.name || "No Driver"} />
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                startIcon={<EditIcon />}
                                                href={`/#/taxi-orders/edit/${row.id}`}
                                            >
                                                Edit
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        ) : (
                            <Box p={2}>
                                <Typography color="gray">No data available</Typography>
                            </Box>
                        )}
                    </Table>
                </TableContainer>
                {/* Pagination Control */}
                <Pagination />
            </Box>
        </Box>
    );
};

export default AcceptedOrders;
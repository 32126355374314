import React, { FC } from 'react';
import { Theme, useMediaQuery } from '@mui/material';
import { Box, Typography, Button, Checkbox } from '@mui/material';
import { useCreatePath, useListContext } from 'react-admin';
import { Link } from 'react-router-dom';
import AddTaskIcon from '@mui/icons-material/AddTask';
import ContentLoader from 'react-content-loader';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import EditButtonComponent from '../../components/buttons/Edit';
import DeleteButtonComponent from '../../components/buttons/Delete';

interface Props {
    bulkIds: any;
}

const LoadingColumnList = () => {
    return (
        <ContentLoader
            width="100%"
            height={200}
            backgroundColor="#222"
            foregroundColor="#333"
            style={{ padding: 20 }}
        >
            <rect x="0" y="6" rx="4" ry="4" width="100%" height="38" />
            <rect x="0" y="55" rx="4" ry="4" width="100%" height="38" />
            <rect x="0" y="104" rx="4" ry="4" width="100%" height="38" />
        </ContentLoader>
    );
};

const LoadedColumnList: FC<Props> = ({ bulkIds }: any) => {

    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

    const [selectedItems, setSelectedItems] = React.useState<number[]>([]);
    const [allItemsSelected, setAllItemsSelected] = React.useState(false);

    const { data } = useListContext();

    if (!data) return null;

    const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;
        setAllItemsSelected(checked);
        if (checked) {
            setSelectedItems(data.map((item) => item.id));
            bulkIds(data.map((item) => item.id));
        } else {
            setSelectedItems([]);
            bulkIds([]);
        }
    };

    const handleSelectItem = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = event.target;
        const id = Number(value);
        if (checked) {
            setSelectedItems((prevState) => [...prevState, id]);
            bulkIds((prevState: any) => [...prevState, id])
            if (selectedItems.length + 1 === data.length) {
                setAllItemsSelected(true);
            }
        } else {
            setSelectedItems((prevState) => prevState.filter((itemId) => itemId !== id));
            bulkIds((prevState: any) => prevState.filter((itemId: any) => itemId !== id));
            setAllItemsSelected(false);
        }
    };

    return (
        <TableContainer sx={{ ml: 4, mr: 4 }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ p: 1, pl: 2, fontSize: 12, color: "gray" }}>
                            <Checkbox checked={allItemsSelected} onChange={handleSelectAll} />
                        </TableCell>
                        <TableCell sx={{ p: 1, pl: 2, fontSize: 12, color: "gray" }}>Vehicle Model</TableCell>
                        {!isSmall &&
                            <>
                                <TableCell sx={{ p: 1, pl: 2, fontSize: 12, color: "gray" }}>Vin Number</TableCell>
                                <TableCell sx={{ p: 1, pl: 2, fontSize: 12, color: "gray" }}>Year</TableCell>
                                <TableCell sx={{ p: 1, pl: 1.5, fontSize: 12, color: "gray" }}>Type</TableCell>
                                <TableCell sx={{ p: 1, pl: 1.5, fontSize: 12, color: "gray" }}>Groups</TableCell>
                            </>
                        }
                        <TableCell sx={{ p: 1, pr: 3, fontSize: 12, color: "gray" }} align="right">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((record) => (
                        <TableRow sx={{ backgroundColor: "#333" }} key={record.id}>
                            <TableCell>
                                <Checkbox
                                    value={record.id}
                                    checked={selectedItems.includes(record.id)}
                                    onChange={handleSelectItem}
                                />
                            </TableCell>
                            <TableCell>
                                <Box sx={{ display: "flex" }}>
                                    <Box sx={{ mr: 3, mt: 1, alignSelf: "center" }}>
                                        <img width={35} src={record.make.logo} />
                                    </Box>
                                    <Box sx={{ display: "unset", alignSelf: "center" }}>
                                        <Typography fontSize={15}>{record.make.name} {record.make_model}</Typography>
                                        <Typography sx={{ color: "gray", fontSize: 12 }}>{record.license_plate}</Typography>
                                    </Box>
                                </Box>
                            </TableCell>
                            {!isSmall &&
                                <>
                                    <TableCell>{record.identification_vin}</TableCell>
                                    <TableCell>{record.make_year}</TableCell>
                                    <TableCell>{record.km_type}</TableCell>
                                    <TableCell>
                                        {record.vehicle_group_assigned.length > 0 ?
                                            <>
                                                {record.vehicle_group_assigned.map((item: any) => (
                                                    <Box sx={{ display: "inline-grid", m: 0.3, cursor: "pointer" }}>
                                                        <Typography sx={{ p: 1.1, textAlign: "center", backgroundColor: "#222", borderRadius: 3, fontSize: 11 }}>{item.vehicle_groups.group_name}</Typography>
                                                    </Box>
                                                ))}
                                            </>
                                            :
                                            <>
                                                <Typography fontSize={11} ml={1} color="gray">No groups</Typography>
                                            </>
                                        }
                                    </TableCell>
                                </>
                            }
                            <TableCell align="right">
                                <EditButtonComponent title="Edit" goTo={`/#/vehicles/${record.id}`} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const ColumnList: FC<Props> = ({ bulkIds }) => {
    const { isLoading } = useListContext();

    const bulkIdsSelected = (data: any) => {
        bulkIds(data);
    }

    return isLoading ? <LoadingColumnList /> : <LoadedColumnList bulkIds={bulkIdsSelected} />;
};

export default ColumnList;